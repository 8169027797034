import React from "react";
import "./App.css";

const blogPosts = [
  {
    title: "Understanding AI and its Impact",
    excerpt: "AI is transforming industries across the globe. From healthcare to finance, explore how artificial intelligence is shaping the future.",
    image: "https://bootpoot.tech/wp-content/uploads/2021/08/ai.jpg",
    author: "James Conner",
    date: "February 20, 2025",
  },
  {
    title: "2025: The Year of LLM and AI",
    excerpt: "2025 marks a pivotal year for Large Language Models (LLMs) and AI technologies, as they become more integrated into industries, transforming workflows and decision-making processes.",
    image: "https://as1.ftcdn.net/v2/jpg/08/95/86/82/1000_F_895868299_z8aR16uHjnkrtnUkzohVQ68m26JBNt4f.jpg",
    author: "Henry Conner",
    date: "December 28, 2024",
  },  
  {
    title: "How Machine Learning is Changing the Workforce",
    excerpt: "Machine learning is automating tasks that were once done manually. Learn how it's reshaping the global job market.",
    image: "https://s3-ap-south-1.amazonaws.com/uploaddocumentstomakeitpublic/wp-content/uploads/2023/09/12125635/1.jpg",
    author: "Henry Conner",
    date: "February 18, 2025",
  },
  {
    title: "The Future of Autonomous Vehicles",
    excerpt: "Autonomous vehicles are on the rise. Find out what advancements are being made and how soon they will change our roads.",
    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Waymo_Jaguar_I-Pace_in_San_Francisco_2023_dllu.jpg/1280px-Waymo_Jaguar_I-Pace_in_San_Francisco_2023_dllu.jpg",
    author: "Henry Conner",
    date: "February 15, 2025",
  },
  {
    title: "Understanding Neural Networks",
    excerpt: "Neural networks are a key component of deep learning.",
    image: "https://dcn.nat.fau.eu/wp-content/uploads/2020-09-22-post-mariusyamajou-04-img-1024x640-1.jpg",
    author: "Henry Conner",
    date: "February 15, 2025",
  },
];
function App() {
  return (
    <div className="App">
<header className="App-header">
  <div className="cover-image">
    <h1>DataBros</h1>
    <p className="blog-description">
      Where AI, machine learning, and data analytics come to life! Whether you're just starting or an expert, our goal is to fuel your curiosity and boost your skills. Started by two brothers passionate about AI, we document our learning journey and dive into real-world applications. Join us as we explore the world of data—one post at a time!
    </p>
  </div>
</header>

      <main className="blog-posts">
        {blogPosts.map((post, index) => (
          <div className="post-tile" key={index}>
            <img src={post.image} alt={post.title} />
            <div className="post-content">
              <h2>{post.title}</h2>
              <p>{post.excerpt}</p>
              <div className="post-meta">
                <span>{post.author}</span> | <span>{post.date}</span>
              </div>
            </div>
          </div>
        ))}
      </main>
    </div>
  );
}

export default App;
